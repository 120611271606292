import "core-js/modules/es.array.push.js";
import * as echarts from 'echarts';
import WDatePicker from '@/components/w/components/w-date-picker/index.vue';
import { post } from '@/libs/request';
import dayjs from 'dayjs';
export default {
  components: {
    WDatePicker
  },
  data() {
    return {
      listQuery: {
        sort: '+date',
        date_between: undefined,
        limit: 30,
        page: 1,
        channel_num: undefined
      },
      pickerOptions: {
        disabledDate: time => time > new Date().setHours(0, 0, 0, 0)
      },
      channelList: [],
      total: 0,
      tableData: [],
      dayList: [],
      itemList: [{
        name: '全部',
        items: [{
          label: '昨日单量',
          value: '150'
        }, {
          label: '昨日交易额',
          value: '300,000'
        }, {
          label: '昨日客单价',
          value: '5,000'
        }, {
          label: '昨日访问总量',
          value: '20,000'
        }]
      }, {
        name: '煤炉',
        items: [{
          label: '昨日单量',
          value: '150'
        }, {
          label: '昨日交易额',
          value: '300,000'
        }, {
          label: '昨日客单价',
          value: '5,000'
        }, {
          label: '昨日访问总量',
          value: '20,000'
        }]
      }, {
        name: '日拍',
        items: [{
          label: '昨日单量',
          value: '150'
        }, {
          label: '昨日交易额',
          value: '300,000'
        }, {
          label: '昨日客单价',
          value: '5,000'
        }, {
          label: '昨日访问总量',
          value: '20,000'
        }]
      },
      // {
      //   name: 'rakuma',
      //   items: [
      //     { label: '昨日单量', value: '150' },
      //     { label: '昨日交易额', value: '300,000' },
      //     { label: '昨日客单价', value: '5,000' },
      //     { label: '昨日访问总量', value: '20,000' },
      //   ]
      // },
      // {
      //   name: 'フリマ',
      //   items: [
      //     { label: '昨日单量', value: '150' },
      //     { label: '昨日交易额', value: '300,000' },
      //     { label: '昨日客单价', value: '5,000' },
      //     { label: '昨日访问总量', value: '20,000' },
      //   ]
      // },
      // {
      //   name: '骏河屋',
      //   items: [
      //     { label: '昨日单量', value: '150' },
      //     { label: '昨日交易额', value: '300,000' },
      //     { label: '昨日客单价', value: '5,000' },
      //     { label: '昨日访问总量', value: '20,000' },
      //   ]
      // },
      {
        name: '其他通贩',
        items: [{
          label: '昨日单量',
          value: '150'
        }, {
          label: '昨日交易额',
          value: '300,000'
        }, {
          label: '昨日客单价',
          value: '5,000'
        }, {
          label: '昨日访问总量',
          value: '20,000'
        }]
      }, {
        name: '用户',
        items: [{
          label: '用户总量',
          value: '1,500'
        }, {
          label: '本周新增用户总量',
          value: '100'
        }]
      }],
      install_total: 0,
      myChart: {},
      chartSearch: {
        site_id: 0,
        date: []
      },
      siteList: []
    };
  },
  computed: {
    is_admin() {
      return this.$store.getters.userinfo.admin_type < 2;
    }
  },
  mounted() {
    const today = new Date().setHours(0, 0, 0, 0);
    let duration = 15;
    if (this.is_admin) {
      duration = 6;
    }
    this.listQuery.date_between = [dayjs().subtract(1, 'day').format('YYYYMMDD').substring(2), dayjs().format('YYYYMMDD').substring(2)];
    // if (this.is_admin) {
    //   request.post('channel/select').then(res => {
    //     this.channelList = res.data.list
    //   })
    // }
    // this.getList()
    this.initSearch();
    this.initChart();
  },
  methods: {
    getList() {
      this.$w_fun.post('channel_data/channel', this.listQuery).then(res => {
        this.sum = res.data.sum || 0;
        this.today = res.data.todayCount || 0;
        this.yesterday = res.data.yesterdayCount || 0;
        this.tableData = res.data.list;
        this.install_total = res.data.installTotal || 0;
        this.dayList = res.data.dayList || [];
      });
    },
    changeSort(sort) {
      this.listQuery.sort = sort;
      this.getList();
    },
    initSearch() {
      post('/siteConfig/select').then(res => {
        this.siteList = res.data.list;
        this.siteList.unshift({
          value: 0,
          label: '全部'
        });
      });
      const today = dayjs();
      const eightDaysAgo = today.clone().subtract(8, 'days');
      this.chartSearch.date = [today.format('YYYY-MM-DD'), eightDaysAgo.format('YYYY-MM-DD')];
    },
    initChart() {
      let chartDom = document.getElementById('chart');
      this.myChart = echarts.init(chartDom);
      let options = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',
          smooth: true
        }, {
          data: [182, 93, 190, 294, 190, 130, 320],
          type: 'line',
          smooth: true
        }]
      };
      options && this.myChart.setOption(options);
    },
    disposeChart() {
      if (this.chartInstance) {
        this.chartInstance.dispose();
        this.chartInstance = null;
      }
    },
    getDatesFrom8DaysAgoToToday() {
      const datesArray = [];
      const today = dayjs();
      const eightDaysAgo = today.clone().subtract(8, 'days');
      // 循环从8天前到今天
      let currentDate = eightDaysAgo;
      while (currentDate.isSameOrBefore(today)) {
        const dateString = currentDate.format('YYYY-MM-DD');
        datesArray.push(dateString);
        currentDate = currentDate.clone().add(1, 'days');
      }
      return datesArray;
    }
  },
  beforeDestroy() {
    this.disposeChart();
  }
};