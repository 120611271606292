var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "p15 w-main-content"
  }, [_c("div", {
    staticClass: "dp-f align-items-center w-data-statist"
  }, _vm._l(_vm.itemList, function (item) {
    return _c("div", {
      staticClass: "w-item"
    }, [_c("div", {
      staticClass: "dp-f flex-d-c"
    }, _vm._l(item.items, function (t) {
      return _c("div", [_c("div", {
        staticClass: "w-r"
      }, [_c("span", {
        staticClass: "f14"
      }, [_vm._v(_vm._s(item.name !== "用户" ? item.name + t.label : t.label))]), _c("i", {
        staticClass: "f20 b"
      }, [_vm._v(_vm._s(t.value))])])]);
    }), 0)]);
  }), 0), _c("section", [_c("div", {
    staticClass: "dp-f justify-content-between f-chart-search"
  }, [_c("div", {
    staticClass: "dp-f-c-c"
  }, [_c("span", [_vm._v("筛选商城：")]), _c("w-select", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      data: _vm.siteList
    },
    model: {
      value: _vm.chartSearch.site_id,
      callback: function ($$v) {
        _vm.$set(_vm.chartSearch, "site_id", $$v);
      },
      expression: "chartSearch.site_id"
    }
  })], 1), _c("div", [_c("span", [_vm._v("筛选日期：")]), _c("w-date-picker", {
    staticStyle: {
      width: "300px"
    },
    attrs: {
      type: "daterange",
      "start-placeholder": "开始",
      "end-placeholder": "结束"
    },
    model: {
      value: _vm.chartSearch.date,
      callback: function ($$v) {
        _vm.$set(_vm.chartSearch, "date", $$v);
      },
      expression: "chartSearch.date"
    }
  })], 1)]), _c("div", {
    ref: "chart",
    staticClass: "f-chart",
    attrs: {
      id: "chart"
    }
  })]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", [_c("div", [_vm._v("导出表格")]), _c("div", {
    staticClass: "dp-f-c-c"
  }, [_c("div"), _c("div"), _c("div")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };